<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-if="searchStatus" status="my-orange" @click="searchStatus = false">隐藏查询</vxe-button>
        <vxe-button v-if="!searchStatus" status="my-orange" icon="fa fa-search" @click="searchStatus = true">查询</vxe-button>
      </template>
    </vxe-toolbar>
    <div v-if="searchStatus" class="search">
      <vxe-form title-align="right" title-width="120" title-colon :data="listQuery" @submit="handleSearch(true)" @reset="handleSearch(true, true)">
        <vxe-form-item title="姓名" span="6">
          <vxe-input v-model="listQuery.param.name" placeholder="请输入姓名" clearable />
        </vxe-form-item>
        <vxe-form-item title="联系方式" span="6">
          <vxe-input v-model="listQuery.param.phone" placeholder="请输入联系方式" clearable />
        </vxe-form-item>
        <vxe-form-item title="学历" span="6">
          <vxe-select v-model="listQuery.param.educational" placeholder="请选择学历" clearable>
            <vxe-option
              v-for="(item, index) in dict.educational"
              :key="index"
              :label="item"
              :value="index"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item title="特长" span="6">
          <vxe-select v-model="listQuery.param.specialty" placeholder="请选择特长" clearable>
            <vxe-option
              v-for="(item, index) in dict.specialty"
              :key="index"
              :label="item"
              :value="index"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item title="职业" span="6">
          <vxe-select v-model="listQuery.param.occupation" placeholder="请选择职业" clearable>
            <vxe-option
              v-for="(item, index) in dict.job"
              :key="index"
              :label="item"
              :value="index"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <CustomList ref="CustomList" :columns="tableColumns" :list-query="listQuery" @getList="getList">
      <template slot="operating" slot-scope="{row}">
        <el-button v-permission="'AuditVolunteerPassButton'" size="small" type="text" @click="handleAudit(row.userId, 1)">通过</el-button>
        <el-button v-permission="'AuditVolunteerRejectButton'" size="small" type="text" @click="handleAudit(row.userId, 2)">拒绝</el-button>
      </template>
    </CustomList>
  </div>
</template>

<script>
import CustomList from '@/components/CustomList/index'
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'
import { mapActions } from 'vuex'
export default {
  name: 'Volunteer',
  components: { CustomList, ...CustomTemplate },
  data() {
    return {
      searchStatus: false,
      dict: {
        educational: {},
        specialty: {},
        job: {}
      },
      genderArray: ['未知', '男', '女'],
      listQuery: {
        param: {
          reviewStatus: 0
        }
      },
      tableColumns: [
        {
          prop: 'name',
          title: '姓名',
          minWidth: 100
        },
        {
          title: '性别',
          formatter: (row, column) => {
            if (row.gender in this.genderArray) {
              return this.genderArray[row.gender]
            } else {
              return ''
            }
          },
          minWidth: 100
        },
        {
          prop: 'age',
          title: '年龄',
          minWidth: 100
        },
        {
          prop: 'phone',
          title: '联系方式',
          minWidth: 120
        },
        {
          prop: 'address',
          title: '地址',
          minWidth: 150
        },
        {
          prop: 'partyMemberName',
          title: '政治面貌',
          minWidth: 100
        },
        {
          prop: 'educationalName',
          title: '学历',
          minWidth: 150
        },
        {
          prop: 'specialtyName',
          title: '特长',
          minWidth: 150
        },
        {
          prop: 'occupationName',
          title: '职业',
          minWidth: 100
        },
        {
          prop: 'creditsBalance',
          title: '积分余额',
          minWidth: 100
        },
        {
          prop: 'currentYearCredits',
          title: '当年总积分',
          minWidth: 100
        },
        {
          prop: 'credits',
          title: '总积分',
          minWidth: 100
        },
        {
          title: '操作',
          fixed: 'right',
          slot: 'operating',
          minWidth: 100
        }
      ]
    }
  },
  created() {
    this.getSysDictList('educational')
    this.getSysDictList('specialty')
    this.getSysDictList('job')
  },
  methods: {
    ...mapActions(['getSysDictServe', 'volunteerPageList', 'auditAudit']),
    getSysDictList(typeCode) {
      this.getSysDictServe({ typeCode }).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        }
        res.data.forEach(res => {
          this.dict[typeCode][res.dictPidVal] = res.dictDesc
        })
      })
    },
    handleSearch(resetCurrentPage = false, resetSearch = false) {
      if (resetSearch) {
        Object.assign(this.listQuery, this.$options.data.call(this).listQuery)
      }
      this.customListGetList(resetCurrentPage)
    },
    customListGetList(resetCurrentPage = false) {
      this.$refs.CustomList.getList(resetCurrentPage)
    },
    getList(params, callback) {
      this.volunteerPageList(params).then(response => {
        callback(response)
      }).catch(() => {})
    },
    handleAudit(mainId, status) {
      this.$confirm(`确认${status === 1 ? '通过' : '拒绝'}吗？`, '', {
        type: 'warning',
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        showInput: status === 2
      }).then(data => {
        let reason = ''
        if (typeof data === 'object' && 'value' in data) {
          reason = data.value
        }
        this.auditAudit({ type: 6, reason, mainId, status }).then(res => {
          if (res.code !== 200) {
            this.$message.error(res.msg)
            return
          }
          this.$message.success('操作成功')
          this.handleSearch(true)
        }).catch(() => {})
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper{
    overflow: auto;
  }
</style>
